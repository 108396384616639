import type { ContentAuth } from "@contentauth/sdk";
import wasmSrc from "@contentauth/sdk/dist/assets/wasm/toolkit_bg.wasm?file";
import workerSrc from "@contentauth/sdk/dist/cai-sdk.worker.min.js?file";
import { useMountEffect } from "@react-hookz/web";
import { useUnmountEffect } from "framer-motion";
import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext, useState } from "react";

export const C2paContext = createContext<ContentAuth | null>(null);
export const useC2paContext = () => useContext(C2paContext);

export type Props = {
  children?: ReactNode;
};

export const C2paProvider: FunctionComponent<Props> = ({ children }) => {
  const [c2paState, setC2paState] = useState<null | ContentAuth>(null);
  useMountEffect(() => {
    async function initC2pa() {
      const { ContentAuth } = await import("@contentauth/sdk");
      const c2pa = new ContentAuth({
        wasmSrc,
        workerSrc,
      });
      setC2paState(c2pa);
    }
    void initC2pa();
  });

  useUnmountEffect(() => {
    void c2paState?.destroy?.();
  });

  return <C2paContext.Provider value={c2paState}>{children}</C2paContext.Provider>;
};
